<template>
  <div>
    <div class="column" style="margin-bottom:0" v-loading="models.loading">
      <div class="flex-row align-items-center margin-bottom-10">
        <div class="margin-right-10">模型更新</div>
      </div>
      <el-table
        ref="datatable"
        :height="$root.docHeight-205"
        size="medium"
        :data="models.list"
      >
        <div slot="empty"><el-empty :image-size="150"></el-empty></div>
        <el-table-column label="产品" prop="productName"></el-table-column>
        <el-table-column label="更新时间" prop="updateTime"></el-table-column>
        <el-table-column v-for="(item,index) in (models.list[0]||{}).move" :key="index" :label="item.name">
          <template slot-scope="props"><span :class="{'color-red':!props.row.move[index].value}">{{ props.row.move[index].value?'已更新':'未更新' }}</span></template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modelManageModelUpdate',
  components:{
  },
  props:{
    modelFreq:{
      type:String,
      default:'week'
    }
  },
  data (){
    return{
   
      models: {
        loading:false,
        list:[]
      },
      
      
    }
  },
  computed:{
    
  },
  created(){
    this.getList()
  },
  mounted(){

  },
  methods:{
    
    //获取列表信息
    getList(){
      this.models.loading=true
      let request=this.$instance.get('/model/getModelUpdate',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
           moduleCode:'mo_xing_guan_li'
          },
      })
      request.then(res=>{
        this.models.loading=false
        if(res.data.code==0){
          this.models.list=res.data.info
        }else{
          this.models.list=[]
        }
      })
      request.catch(()=>{
        this.models.loading=false
      })
      return request
    },
  },
  
  watch: {
    "modelFreq" : {
      handler:function(){
        this.getList()
      },
      deep:true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>

 
</style>
